.main-navigation {
    display: flex;
    padding: 0 20px;
    width: 90vw;
    min-height: 90vh;
    opacity: 0;
    transition: opacity .5s ease-out;

    .layout--overlay & {
        opacity: 1;
        transition: opacity .5s ease-out .2s;
    }

    .layout--overlay-close & {
        opacity: 0;
        transition: opacity .25s ease-in;
    }

    a {
        text-decoration: none;
        @include highlight(map-get($colors, violet));

        > span > br {
            display: none;
        }
    }

    .level_1 {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-height: 700px;

        > li {
            padding: 10px 0;

            > a {
                font-size: 36px;
                line-height: 1;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }
    }

    .level_2 {
        margin: 10px 0;

        &--empty {
            display: none;
        }

        li {
            display: none;

            > a {
                margin-left: 30px;
            }

            &.category {
                display: block;
                padding: 10px 0 10px;

                > a {
                    font-size: 15px;
                    font-weight: map-get($font-weight, black);
                    text-transform: uppercase;
                }
            }
        }
    }

    @include screen(medium) {
        height: auto;
        width: auto;
        max-width: 1200px;
        margin: 100px auto 0;

        @include clear-after;

        a {
            > span > br {
                display: block;
            }
        }

        .level_1 {
            display: block;

            > li {
                padding: 0;
                margin-bottom: 60px;

                &:nth-child(1),
                &:nth-child(2) {
                    float: left;
                    width: 33.33333333%;
                }

                &:nth-child(5) {
                    margin-top: -20px;
                }

                > a {
                    margin-bottom: 10px;
                }
            }
        }

        .level_2 {
            padding: 0 10px;
            overflow: hidden;

            &--empty {
                display: block;
            }

            li {
                display: inherit;
                padding: 5px 0;

                &.category {
                    padding: 0 0 20px;
                }

                > a {
                    + .level_3 {
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}

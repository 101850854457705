.layout--blog {
    .section-header {
        height: 110px;
    }

    .section-main {
        &__inside {
            margin: 0 auto;
        }

        h1 {
            display: none;
        }

        .news-list__inside {
            height: 80vh;
            min-height: 400px;
        }

        @include screen(medium) {
            &__inside {
                margin: 0 0 0 auto;
            }

            h1 {
                position: relative;
                display: block;
                margin: -60px 60% -120px 0;
                font-size: 65px;
                line-height: 60px;
                text-transform: uppercase;
                text-align: right;
                z-index: 10;
            }
        }
    }
}

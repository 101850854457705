.social-element {
    &__icon {
        width: 20px;
        height: 20px;
        margin-left: 25px;

        @include social-icons;
    }
}

.current-page {
    margin: 15px 0 20px;
    font-size: 20px;
    font-weight: map-get($font-weight, bold);

    @include screen(medium) {
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        width: 33.3333%;
        margin: 0;
        padding: 0 40px;
        line-height: 1;
        text-align: right;
    }
}

.page-navigation,
.news-navigation {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 50px;
    padding: 0;
    font-weight: map-get($font-weight, light);
    line-height: 1.125;

    a {
        position: relative;
        display: inline-block;
        padding: 0 12px;
        text-decoration: none;
    }

    strong,
    time {
        font-weight: map-get($font-weight, black);

        .layout--team & {
            display: none;
        }
    }

    &__prev,
    &__next {
        flex-grow: 1;
        width: 50%;
    }

    &__prev {
        text-align: left;

        a {
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "< ";
            }
        }
    }

    &__next {
        text-align: right;

        a {

            &:after {
                position: absolute;
                top: 0;
                right: 0;
                content: " >";
            }
        }
    }

    @include screen(medium) {
        position: absolute;
        top: -100px;
        left: 20px;
        right: 20px;
        font-weight: map-get($font-weight, light);
    }
}

.page-navigation {
    span {
        display: block;
        overflow: hidden;
    }

    &__prev {
        strong {
            float: left;
            padding-right: 5px;
        }
    }

    &__next {
        strong {
            float: right;
            padding-left: 5px;
        }
    }
}

.news-navigation {
    time {
        display: block;
    }

    span {
        display: block;
        height: 1.5em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    @include screen(medium) {
        &__prev {
            time {
                float: left;
                padding-right: 5px;
            }
        }

        &__next {
            time {
                float: right;
                padding-left: 5px;
            }
        }
    }
}

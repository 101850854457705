.tns-inner {
    overflow: hidden;
    max-width: calc(100vw - 20px);
}

.news-list {
    overflow: hidden;
    text-decoration: inherit;

    &__inside {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 80vh;
        min-height: 400px;
        padding: 80px 40px 60px;
        font-size: 22px;
        line-height: 1.2;
        text-decoration: none;
    }

    h2 {
        margin: 30px 0 60px;
        transition: all .4s;
        font-size: 32px;
        font-weight: map-get($font-weight, black);
        text-transform: initial;
    }

    &__link {
        @include link-button(100, 37);
        font-size: 16px;
        font-weight: map-get($font-weight, regular);
        transition: opacity .4s;
    }

    @include screen(medium) {
        &.tns-slide-active + .news-list > .news-list__inside {
            border-left: 1px solid rgba($text-color, .25);
        }
    }

    $highlight-colors: (blue, yellow, green, pink);
    @each $background in (orange, blue, yellow, green, pink) {
        $current-colors: ();

        @each $color in $highlight-colors {
            @if ($color != $background) {
                $current-colors: append($current-colors, $color);
            }
        }

        $index: 1;
        $length: length($current-colors);

        .layout--#{$background} & {
            @each $color in $current-colors {
                &:nth-child(#{$length}n+#{$index}) {
                    .news-list__inside {
                        @include postit($color);
                    }

                    h2 {
                        @include highlight(map-get($contrasts, $color), true);
                    }
                }

                $index: $index + 1;
            }
        }
    }

    .layout--blue & {
        &__inside:nth-child(3n+1) {

        }

        &__inside:nth-child(3n+2) {

        }

        &__inside:nth-child(3n+3) {

        }
    }

    .layout--yellow & {

    }

    .layout--green & {

    }

    .layout--pink & {

    }
}

.layout--team {

    .section-team-header {
        position: relative;

        &__inside {
            margin: 0 20px;
        }
    }

    .section-main {
        &__inside {
            border-top: 2px solid map-get($colors, text);
        }
    }

    .mod_author_news {
        display: flex;
        justify-content: stretch;

        .news-list {
            &__inside {
                height: 100%;
            }
        }
    }

    @include screen(medium) {
        .section-team-header {
            &__inside {
                position: relative;
                max-width: 1200px;
                margin: 0 auto;
            }
        }

        .section-main {
            &__inside {
                border-top: none;
            }
        }
    }
}

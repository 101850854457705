/**
 * Responsive videos (see #1348)
 */
.video_container video {
    max-width:100%;
    height:auto;
}
.responsive {
    position:relative;
    height:0;
}
.responsive iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
.responsive.ratio-169 {
    padding-bottom:56.25%;
}
.responsive.ratio-1610 {
    padding-bottom:62.5%;
}
.responsive.ratio-219 {
    padding-bottom:42.8571%;
}
.responsive.ratio-43 {
    padding-bottom:75%;
}
.responsive.ratio-32 {
    padding-bottom:66.6666%;
}

.section-header {
    min-height: 140px;

    &__logo {
        display: block;
        margin: 0 0 0 30px;
        width: 165px;
        height: 48px;
        background: url("../images/logo-sw.svg") 0 20px no-repeat;
        background-size: contain;
        text-indent: -999em;

        .layout--home & {
            background-image: url("../images/logo.svg");
        }
    }

    &__navigation {
        position: fixed;
        z-index: 100;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        background: linear-gradient(225deg, map-get($colors, yellow) 50%, rgba(255, 255, 255, 0) 50%);
        background-size: 300vw 300vh;
        background-position: 0 -150vh;
        background-repeat: no-repeat;
        transition: background-position .5s ease-in-out;

        &:before {
            content: "";
            position: absolute;
            right: -100px;
            right: calc(-100px + (env(safe-area-inset-right) / 2));
            top: -100px;
            width: 200px;
            height: 200px;
            z-index: -1;
            transform: rotateZ(45deg);

            @each $color, $contrast in $contrasts {
                .layout--#{$color} & {
                    background: map-get($colors, $color);
                }
            }

            .layout--home &,
            .layout--navigation &,
            .layout--blog & {
                background: map-get($colors, gray);
            }
        }

        .layout--overlay &:before {
            display: none;
        }

        &-toggle {
            position: absolute;
            top: 15px;
            right: 10px;
            right: calc(10px + env(safe-area-inset-right));
            padding: 15px 15px;
            display: block;
            outline: none;
            cursor: pointer;
            transition-property: opacity, filter;
            transition-duration: 0.15s;
            transition-timing-function: linear;
            font: inherit;
            line-height: 0;
            background-color: transparent;
            border: 0;
            margin: 0;
            overflow: visible;

            &:hover {
                opacity: .7;
            }

            &-box {
                width: 20px;
                height: 12px;
                display: inline-block;
                position: relative;
            }

            &-inner {
                top: 50%;
                margin-top: -4px;
                transition-duration: 0.075s;
                transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

                &,
                &::before,
                &::after {
                    display: block;
                    width: 20px;
                    height: 2px;
                    background-color: $text-color;
                    border-radius: 2px;
                    position: absolute;
                    transition-property: transform;
                    transition-duration: 0.15s;
                    transition-timing-function: ease;
                    content: "";
                }

                &::before {
                    top: -6px;
                    transition: top 0.075s 0.12s ease,
                    opacity 0.075s ease;
                }

                &::after {
                    bottom: -6px;
                    transition: bottom 0.075s 0.12s ease,
                    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
                }
            }

            // Animation for Navi-Burger
            .layout--overlay & {
                &-inner {
                    transform: rotate(45deg);
                    transition-delay: 0.12s;
                    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

                    &::before {
                        top: 0;
                        opacity: 0;
                        transition: top 0.075s ease,
                        opacity 0.075s 0.12s ease;
                    }

                    &::after {
                        bottom: 0;
                        transform: rotate(-90deg);
                        transition: bottom 0.075s ease,
                        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
                    }
                }
            }
        }

        // Navigation Overlay
        .layout--overlay & {
            overflow: auto;
            z-index: 1000;
            width: 100vw;
            height: 100vh;
            padding: 30px 0;
            background-position: -150vw 0;
        }

        .layout--overlay-close & {
            background-position: 0 -150vh;
        }
    }

    @include screen(landscape, orientation) {
        &__logo {
            margin: 0 auto;
        }
    }

    @include screen(medium) {
        min-height: 200px;

        &__logo {
            height: 90px;
            margin: 0 auto;
            background-position: 0 40px;
        }

        &__navigation {
            &-toggle {
                top: 25px;
                right: 20px;
            }
        }
    }
}

body.layout--overlay {
    overflow: hidden;
}

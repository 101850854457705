@charset "UTF-8";
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * neuzeit-grotesk:
 *   - http://typekit.com/eulas/0000000000000000000178a5
 *   - http://typekit.com/eulas/0000000000000000000178a6
 *   - http://typekit.com/eulas/0000000000000000000178a7
 *   - http://typekit.com/eulas/0000000000000000000178a8
 *
 * © 2009-2019 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2019-10-30 07:25:34 UTC"}*/
@import url("https://p.typekit.net/p.css?s=1&k=brg4rvj&ht=tk&f=12101.12102.12103.12104&a=1536722&app=typekit&e=css");
@font-face {
  font-family: "neuzeit-grotesk";
  src: url("https://use.typekit.net/af/8f7727/0000000000000000000178a5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/8f7727/0000000000000000000178a5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/8f7727/0000000000000000000178a5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "neuzeit-grotesk";
  src: url("https://use.typekit.net/af/cde90e/0000000000000000000178a6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/cde90e/0000000000000000000178a6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/cde90e/0000000000000000000178a6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "neuzeit-grotesk";
  src: url("https://use.typekit.net/af/d7bb7b/0000000000000000000178a7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/d7bb7b/0000000000000000000178a7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/d7bb7b/0000000000000000000178a7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "neuzeit-grotesk";
  src: url("https://use.typekit.net/af/7d4af5/0000000000000000000178a8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/7d4af5/0000000000000000000178a8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/7d4af5/0000000000000000000178a8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}
html[lang=it] body {
  text-align: justify;
}

input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: 99%;
  border-radius: 0;
}

textarea,
button,
input[type=email],
input[type=tel],
input[type=text],
input[type=submit],
input[type=search] {
  -webkit-appearance: none;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  display: none;
}

textarea[disabled],
button[disabled],
input[type=email][disabled],
input[type=tel][disabled],
input[type=text][disabled],
input[type=submit][disabled],
input[type=search][disabled] {
  cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type=checkbox][disabled] + label,
input[type=radio][disabled] + label {
  cursor: not-allowed;
}

table {
  font-size: inherit;
  border-collapse: collapse;
  border-spacing: 0;
}

table td, table th {
  padding: 4px 15px;
  vertical-align: top;
}

tr > :first-child {
  padding-left: 0;
}

th {
  font-weight: 700;
}

caption,
strong,
b {
  font-weight: 700;
}

i,
em {
  font-weight: 400;
}

ul {
  margin: 0 0 -5px;
  padding: 0 0 0 1em;
}

li {
  margin: 0 0 5px;
  padding: 0;
  line-height: 1.4;
}

ul ul {
  margin-bottom: 0;
}

form,
figure,
blockquote {
  margin: 0;
  padding: 0;
}

img,
iframe {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: middle;
  border: 0;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption {
  display: block;
}

html {
  font-size: 16px;
  height: 100%;
}

body {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: #3a3a3a;
  font-family: neuzeit-grotesk, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.57;
}
@media screen and (min-width: 960px) {
  body {
    font-size: 16px;
    line-height: 1.75;
  }
}

h1, h2, h3, h4, h5, h6, p {
  margin: 1.5em 0;
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: #3a3a3a;
  text-decoration: underline;
}

i {
  font-style: normal;
}

b,
strong {
  font-weight: 700;
}

caption,
th,
td {
  text-align: start;
}

abbr,
acronym {
  font-variant: normal;
  border-bottom: 1px dotted #3c3c3c;
  cursor: help;
}

blockquote,
q {
  quotes: none;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

del {
  text-decoration: line-through;
}

ins {
  text-decoration: none;
}

pre,
code {
  font-family: monospace;
}

/* Vertical align */
.float_left {
  float: left;
}

.float_right {
  float: right;
}

/* Clear floats */
.clear,
#clear {
  height: 0.1px;
  font-size: 0.1px;
  line-height: 0.1px;
  clear: both;
}

/* Hide invisible elements */
.invisible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.mod_navigation ul, .mod_navigation li,
.mod_changelanguage ul,
.mod_changelanguage li,
.mod_breadcrumb ul,
.mod_breadcrumb li,
.mod_team_topics ul,
.mod_team_topics li,
.mod_topic_team ul,
.mod_topic_team li,
.page-navigation ul,
.page-navigation li,
.news-navigation ul,
.news-navigation li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: initial;
}

@keyframes postit-corner {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/**
 * Responsive videos (see #1348)
 */
.video_container video {
  max-width: 100%;
  height: auto;
}

.responsive {
  position: relative;
  height: 0;
}

.responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive.ratio-169 {
  padding-bottom: 56.25%;
}

.responsive.ratio-1610 {
  padding-bottom: 62.5%;
}

.responsive.ratio-219 {
  padding-bottom: 42.8571%;
}

.responsive.ratio-43 {
  padding-bottom: 75%;
}

.responsive.ratio-32 {
  padding-bottom: 66.6666%;
}

@media screen and (min-width: 960px) {
  .row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .row > * {
    padding-left: 20px;
    padding-right: 20px;
  }
  .col, .col-3, .col-4, .col-6, .col-8 {
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .col-right {
    float: right;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33333333%;
  }
  .col-6 {
    width: 50%;
  }
  .col-8 {
    width: 66.66666667%;
  }
  .offset-3 {
    margin-left: 25%;
  }
  .offset-4 {
    margin-left: 33.33333333%;
  }
  .offset-8 {
    margin-left: 66.66666667%;
  }
}
.section-header {
  min-height: 140px;
}
.section-header__logo {
  display: block;
  margin: 0 0 0 30px;
  width: 165px;
  height: 48px;
  background: url("../images/logo-sw.svg") 0 20px no-repeat;
  background-size: contain;
  text-indent: -999em;
}
.layout--home .section-header__logo {
  background-image: url("../images/logo.svg");
}
.section-header__navigation {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  background: linear-gradient(225deg, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 300vw 300vh;
  background-position: 0 -150vh;
  background-repeat: no-repeat;
  transition: background-position 0.5s ease-in-out;
}
.section-header__navigation:before {
  content: "";
  position: absolute;
  right: -100px;
  right: calc(-100px + env(safe-area-inset-right) / 2);
  top: -100px;
  width: 200px;
  height: 200px;
  z-index: -1;
  transform: rotateZ(45deg);
}
.layout--orange .section-header__navigation:before {
  background: #f4a100;
}
.layout--blue .section-header__navigation:before {
  background: #a1faf9;
}
.layout--yellow .section-header__navigation:before {
  background: #e9fe5f;
}
.layout--green .section-header__navigation:before {
  background: #b2fbac;
}
.layout--pink .section-header__navigation:before {
  background: #fbacf6;
}
.layout--violet .section-header__navigation:before {
  background: #cb66ff;
}
.layout--neon .section-header__navigation:before {
  background: #ffcc02;
}
.layout--home .section-header__navigation:before, .layout--navigation .section-header__navigation:before, .layout--blog .section-header__navigation:before {
  background: #e0e0e0;
}
.layout--overlay .section-header__navigation:before {
  display: none;
}
.section-header__navigation-toggle {
  position: absolute;
  top: 15px;
  right: 10px;
  right: calc(10px + env(safe-area-inset-right));
  padding: 15px 15px;
  display: block;
  outline: none;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  line-height: 0;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.section-header__navigation-toggle:hover {
  opacity: 0.7;
}
.section-header__navigation-toggle-box {
  width: 20px;
  height: 12px;
  display: inline-block;
  position: relative;
}
.section-header__navigation-toggle-inner {
  top: 50%;
  margin-top: -4px;
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.section-header__navigation-toggle-inner, .section-header__navigation-toggle-inner::before, .section-header__navigation-toggle-inner::after {
  display: block;
  width: 20px;
  height: 2px;
  background-color: #3a3a3a;
  border-radius: 2px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  content: "";
}
.section-header__navigation-toggle-inner::before {
  top: -6px;
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.section-header__navigation-toggle-inner::after {
  bottom: -6px;
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.layout--overlay .section-header__navigation-toggle-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.layout--overlay .section-header__navigation-toggle-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.layout--overlay .section-header__navigation-toggle-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.layout--overlay .section-header__navigation {
  overflow: auto;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  padding: 30px 0;
  background-position: -150vw 0;
}
.layout--overlay-close .section-header__navigation {
  background-position: 0 -150vh;
}
@media screen and (orientation: landscape) {
  .section-header__logo {
    margin: 0 auto;
  }
}
@media screen and (min-width: 960px) {
  .section-header {
    min-height: 200px;
  }
  .section-header__logo {
    height: 90px;
    margin: 0 auto;
    background-position: 0 40px;
  }
  .section-header__navigation-toggle {
    top: 25px;
    right: 20px;
  }
}

body.layout--overlay {
  overflow: hidden;
}

.section-footer {
  padding: 60px 20px 20px;
  font-weight: 400;
  text-align: center;
}
.section-footer p {
  margin: 0;
}
.section-footer__slogan {
  margin: 0 0 25px !important;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}
.section-footer__contact {
  max-width: 800px;
  margin: 0 auto;
}
.section-footer__social {
  margin-bottom: 25px;
}
.section-footer__social-icon {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  display: inline-block;
  text-align: left;
  text-indent: -999em;
  background: center bottom no-repeat;
  background-size: contain;
}
.section-footer__social-icon--facebook {
  background-image: url("../images/icon__facebook.svg");
}
.section-footer__social-icon--linkedin {
  background-image: url("../images/icon__linkedin.svg");
}
.section-footer__social-icon--linkedin-dark {
  background-image: url("../images/icon__linkedin--dark.svg");
}
.section-footer__social-icon--email {
  background-image: url("../images/icon__email.svg");
}
.section-footer__social-icon--print {
  background-image: url("../images/icon__print.svg");
}
.section-footer__social-icon--twitter {
  background-image: url("../images/icon__twitter.svg");
}
.section-footer__social-icon--xing {
  background-image: url("../images/icon__xing.svg");
}
@media screen and (min-width: 960px) {
  .section-footer__social {
    margin-bottom: 0;
  }
}
.section-footer__copyright {
  margin-top: 60px;
  font-size: 11px;
}
.section-footer__copyright .mod_navigation, .section-footer__copyright ul, .section-footer__copyright li {
  display: inline;
}
.section-footer__copyright li:before {
  content: "  |  ";
  text-decoration: none;
}
.section-footer__copyright a {
  position: relative;
  display: inline-block;
}

body {
  position: relative;
  background: #f4a100;
}
body.layout--orange {
  background: #f4a100;
}
body.layout--blue {
  background: #a1faf9;
}
body.layout--yellow {
  background: #e9fe5f;
}
body.layout--green {
  background: #b2fbac;
}
body.layout--pink {
  background: #fbacf6;
}
body.layout--violet {
  background: #cb66ff;
}
body.layout--neon {
  background: #ffcc02;
}

.layout__columns,
.section-blog {
  position: relative;
  margin: 0 20px;
}
@media screen and (min-width: 960px) {
  .layout__columns--3cl:after, .layout__columns--2cll:after,
  .section-blog--3cl:after,
  .section-blog--2cll:after {
    content: "";
    display: table;
    clear: both;
  }
  .layout__columns--3cl > .section-left,
  .layout__columns--3cl > .section-main,
  .layout__columns--3cl > .section-right, .layout__columns--2cll > .section-left,
  .layout__columns--2cll > .section-main,
  .layout__columns--2cll > .section-right,
  .section-blog--3cl > .section-left,
  .section-blog--3cl > .section-main,
  .section-blog--3cl > .section-right,
  .section-blog--2cll > .section-left,
  .section-blog--2cll > .section-main,
  .section-blog--2cll > .section-right {
    float: left;
    width: 33.33333333%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .layout__columns--2cll > .section-main,
  .section-blog--2cll > .section-main {
    width: 66.66666666%;
  }
}
@media screen and (min-width: 1200px) {
  .layout__columns,
  .section-blog {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.section-blog {
  padding-top: 80px;
}

.mod_article h1,
.section-left h1,
.news-details h1 {
  margin: -5px 0 0;
  font-size: 50px;
  line-height: 45px;
  text-transform: uppercase;
}
@media screen and (min-width: 960px) {
  .mod_article h1,
  .section-left h1,
  .news-details h1 {
    line-height: 1;
  }
}
.mod_article h2,
.section-left h2,
.news-details h2 {
  margin: 4em 0 1.5em;
  font-size: 18px;
  line-height: 1.2222;
  font-weight: 700;
  text-transform: uppercase;
}
.mod_article h3,
.section-left h3,
.news-details h3 {
  margin: 3em 0 1.5em;
  font-size: 18px;
  line-height: 1.2222;
  font-weight: 700;
}

.layout--home:before {
  content: "";
  position: absolute;
  width: 150vw;
  height: 150vw;
  z-index: -1;
  background: linear-gradient(45deg, #f4a100 50%, #e0e0e0 50%);
  background-size: 150vw 150vw;
  background-position: 0 0;
  left: -50vw;
  top: calc(-150vw + 140px);
  animation: corner-small-home 1.5s ease-in-out 0.5s forwards;
}
@supports (-ms-ime-align: auto) {
  .layout--home:before {
    animation: corner-small-edge 1.5s ease-in-out 0.5s forwards;
  }
}
@keyframes corner-small-home {
  0% {
    top: calc(-150vw + 140px);
  }
  100% {
    top: calc(-50vw + 85px);
  }
}
@keyframes corner-medium-home {
  0% {
    top: calc(-150vw + 140px);
  }
  100% {
    top: calc(-100vw + 250px);
  }
}
@keyframes corner-small-edge {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(70%);
  }
}
@keyframes corner-medium-edge {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(42%);
  }
}
@media screen and (orientation: landscape) {
  .layout--home:before {
    top: calc(-150vw + 140px);
    animation: corner-medium-home 1.5s ease-in-out 0.5s forwards;
  }
  @supports (-ms-ime-align: auto) {
    .layout--home:before {
      animation: corner-medium-edge 1.5s ease-in-out 0.5s forwards;
    }
  }
}
@media screen and (min-width: 960px) {
  .layout--home:before {
    top: calc(-150vw + 140px);
    animation: corner-medium-home 1.5s ease-in-out 0.5s forwards;
  }
  @supports (-ms-ime-align: auto) {
    .layout--home:before {
      animation: corner-medium-edge 1.5s ease-in-out 0.5s forwards;
    }
  }
}
.layout--home .section-header {
  position: relative;
  min-height: 100vh;
}
.layout--home .section-header__logo {
  opacity: 0;
  animation: logo-home 0.25s ease-in-out 1.25s forwards;
}
@keyframes logo-home {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.layout--home .section-header__scroll {
  position: absolute;
  left: 50%;
  bottom: 20vh;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 700;
  color: #ffcc02;
  text-transform: uppercase;
  opacity: 0;
  animation: scroll-home 0.25s ease-in-out 2.5s forwards;
}
@media screen and (min-width: 960px) {
  .layout--home .section-header__scroll {
    bottom: 10vh;
  }
}
.layout--home .section-header__scroll::before {
  display: block;
  position: absolute;
  top: 30px;
  left: 50%;
  width: 2px;
  height: 39px;
  margin-left: -1px;
  background: #3a3a3a;
  content: "";
}
.layout--home .section-header__scroll::after {
  display: block;
  position: absolute;
  top: 30px;
  left: 50%;
  width: 2px;
  height: 13px;
  margin-left: -1px;
  background: #ffcc02;
  content: "";
  animation-name: vertical-scroll;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 3s;
}
@keyframes scroll-home {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes vertical-scroll {
  0% {
    height: 13px;
    opacity: 1;
  }
  20% {
    height: 13px;
  }
  30% {
    transform: translateY(0);
    height: 40px;
  }
  80% {
    transform: translateY(40px);
    height: 0;
  }
  81% {
    transform: translateY(0);
    height: 0;
  }
  100% {
    height: 13px;
  }
}
.layout--home .section-header h1 {
  position: absolute;
  top: 70vh;
  width: 100%;
  transform: translateY(-50%);
  font-size: calc(4vw + 5vh);
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
  animation: title-small-home 1.5s ease-out 0.5s forwards;
}
@keyframes title-small-home {
  0% {
    opacity: 0;
    top: 70vh;
  }
  100% {
    opacity: 1;
    top: 40vh;
  }
}
@media screen and (min-width: 960px) {
  .layout--home .section-header h1 {
    padding-right: 40%;
    font-size: 8vw;
    text-align: right;
    animation: title-medium-home 1.5s ease-out 0.5s forwards;
  }
  @keyframes title-medium-home {
    0% {
      opacity: 0;
      top: 70vh;
    }
    100% {
      opacity: 1;
      top: 50vh;
    }
  }
}
.layout--home .section-main__inside.animated {
  opacity: 0.2;
  transform: translateY(80px);
}
.layout--home .section-main__inside.animated.entered {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s ease-out;
}
.layout--home .section-main__inside > .mod_article.first {
  margin-top: -1em;
}
.layout--home .section-main .content-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
}
.layout--home .section-main .content-text b,
.layout--home .section-main .content-text strong {
  font-weight: 900;
}
.layout--home .section-main .content-text + .content-text {
  max-width: none;
  font-size: 45px;
  font-weight: 900;
  line-height: 1;
  text-align: center;
}
@media screen and (min-width: 960px) {
  .layout--home .section-main .content-text {
    max-width: 500px;
    margin: 0 auto;
  }
  .layout--home .section-main .content-text + .content-text {
    font-size: 75px;
  }
}
.layout--home .section-wir-machen h1 {
  margin: 0 0 -20px;
  font-size: 65px;
  line-height: 60px;
}
@media screen and (min-width: 600px) {
  .layout--home .section-wir-machen .mod_article {
    margin-left: -20px;
    margin-right: -20px;
  }
  .layout--home .section-wir-machen .mod_article:after {
    content: "";
    display: table;
    clear: both;
  }
  .layout--home .section-wir-machen .mod_article h1 {
    margin: 0 0 -20px 20px;
  }
  .layout--home .section-wir-machen .mod_article > .postit {
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    width: 50%;
  }
}
@media screen and (min-width: 960px) {
  .layout--home .section-wir-machen {
    max-width: 1140px;
    margin: 250px auto 0;
  }
  .layout--home .section-wir-machen .mod_article h1 {
    margin: -115px 0 0;
    font-size: 75px;
    line-height: 1;
    text-align: right;
    word-spacing: 100vw;
  }
  .layout--home .section-wir-machen .mod_article h1,
  .layout--home .section-wir-machen .mod_article > .postit {
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    width: 33.33333333%;
  }
}
.layout--home .section-blog h1 {
  position: relative;
  margin: 0 0 -120px;
  font-size: 65px;
  line-height: 60px;
  z-index: 10;
}
@media screen and (min-width: 960px) {
  .layout--home .section-blog h1 {
    font-size: 75px;
    line-height: 1;
    margin: 0 0 -150px 20%;
  }
}

.layout--navigation .layout__columns {
  max-width: none;
}
.layout--navigation .section-main__inside {
  max-width: 600px;
  margin: 0 auto;
}
.layout--navigation .section-main h1 {
  margin: 0;
  font-size: 65px;
  line-height: 60px;
  text-transform: uppercase;
}
@media screen and (min-width: 960px) {
  .layout--navigation .section-header {
    height: 50vw;
    max-height: 200px;
  }
  .layout--navigation .section-main {
    text-align: right;
  }
  .layout--navigation .section-main__inside {
    margin: 0 50% 0 auto;
  }
  .layout--navigation .section-main h1 {
    font-size: 75px;
    line-height: 80px;
  }
}

.layout--topic .section-main {
  hyphens: auto;
}
.layout--topic .section-main__inside {
  border-top: 2px solid #3a3a3a;
  padding-top: 1.3em;
}
.layout--topic .mod_topic_news {
  display: flex;
  justify-content: stretch;
}
.layout--topic .mod_topic_news .news-list__inside {
  height: 100%;
}
@media screen and (min-width: 960px) {
  .layout--topic .section-left {
    text-align: right;
  }
  .layout--topic .section-left h1 {
    margin-top: 60px;
  }
  .layout--topic .section-right__inside {
    border-top: 2px solid #3a3a3a;
  }
  .layout--topic .section-main__inside {
    padding-top: 2em;
  }
}

.layout--team .section-team-header {
  position: relative;
}
.layout--team .section-team-header__inside {
  margin: 0 20px;
}
.layout--team .section-main__inside {
  border-top: 2px solid #3a3a3a;
}
.layout--team .mod_author_news {
  display: flex;
  justify-content: stretch;
}
.layout--team .mod_author_news .news-list__inside {
  height: 100%;
}
@media screen and (min-width: 960px) {
  .layout--team .section-team-header__inside {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }
  .layout--team .section-main__inside {
    border-top: none;
  }
}

.layout--content .section-left__inside {
  display: flex;
  align-items: baseline;
}
.layout--content .section-left .social-element {
  margin: 0 0 2px 80px;
}
@media screen and (min-width: 960px) {
  .layout--content .section-left {
    text-align: right;
  }
  .layout--content .section-left__inside {
    display: initial;
  }
  .layout--content .section-left .social-element {
    margin: 80px 0 0;
  }
  .layout--content .section-main,
  .layout--content .section-right {
    min-height: 400px;
  }
  .layout--content .section-main__inside,
  .layout--content .section-right__inside {
    border-top: 2px solid #3a3a3a;
  }
}

.layout--blog .section-header {
  height: 110px;
}
.layout--blog .section-main__inside {
  margin: 0 auto;
}
.layout--blog .section-main h1 {
  display: none;
}
.layout--blog .section-main .news-list__inside {
  height: 80vh;
  min-height: 400px;
}
@media screen and (min-width: 960px) {
  .layout--blog .section-main__inside {
    margin: 0 0 0 auto;
  }
  .layout--blog .section-main h1 {
    position: relative;
    display: block;
    margin: -60px 60% -120px 0;
    font-size: 65px;
    line-height: 60px;
    text-transform: uppercase;
    text-align: right;
    z-index: 10;
  }
}

.main-navigation {
  display: flex;
  padding: 0 20px;
  width: 90vw;
  min-height: 90vh;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.layout--overlay .main-navigation {
  opacity: 1;
  transition: opacity 0.5s ease-out 0.2s;
}
.layout--overlay-close .main-navigation {
  opacity: 0;
  transition: opacity 0.25s ease-in;
}
.main-navigation a {
  text-decoration: none;
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #cb66ff 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
.main-navigation a:hover {
  background-position: 0 0;
}
.main-navigation a > span > br {
  display: none;
}
.main-navigation .level_1 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-height: 700px;
}
.main-navigation .level_1 > li {
  padding: 10px 0;
}
.main-navigation .level_1 > li > a {
  font-size: 36px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.main-navigation .level_2 {
  margin: 10px 0;
}
.main-navigation .level_2--empty {
  display: none;
}
.main-navigation .level_2 li {
  display: none;
}
.main-navigation .level_2 li > a {
  margin-left: 30px;
}
.main-navigation .level_2 li.category {
  display: block;
  padding: 10px 0 10px;
}
.main-navigation .level_2 li.category > a {
  font-size: 15px;
  font-weight: 900;
  text-transform: uppercase;
}
@media screen and (min-width: 960px) {
  .main-navigation {
    height: auto;
    width: auto;
    max-width: 1200px;
    margin: 100px auto 0;
  }
  .main-navigation:after {
    content: "";
    display: table;
    clear: both;
  }
  .main-navigation a > span > br {
    display: block;
  }
  .main-navigation .level_1 {
    display: block;
  }
  .main-navigation .level_1 > li {
    padding: 0;
    margin-bottom: 60px;
  }
  .main-navigation .level_1 > li:nth-child(1), .main-navigation .level_1 > li:nth-child(2) {
    float: left;
    width: 33.33333333%;
  }
  .main-navigation .level_1 > li:nth-child(5) {
    margin-top: -20px;
  }
  .main-navigation .level_1 > li > a {
    margin-bottom: 10px;
  }
  .main-navigation .level_2 {
    padding: 0 10px;
    overflow: hidden;
  }
  .main-navigation .level_2--empty {
    display: block;
  }
  .main-navigation .level_2 li {
    display: inherit;
    padding: 5px 0;
  }
  .main-navigation .level_2 li.category {
    padding: 0 0 20px;
  }
  .main-navigation .level_2 li > a + .level_3 {
    padding: 10px 0;
  }
}

.postit {
  overflow: hidden;
  margin: 20px 0;
}
.postit p {
  margin: 0;
}
.postit__number {
  font-size: 75px;
  font-weight: 900;
  line-height: 1;
}
.postit__title {
  margin: 40px 0 15px !important;
  font-size: 15px;
  font-weight: 900;
  text-transform: uppercase;
}
.postit__link {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 166px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  margin-top: 2em;
}
.postit__link svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.postit__link svg rect {
  fill: none;
  stroke: #3a3a3a;
  stroke-width: 5;
  stroke-dasharray: 422, 0;
  transition: all 0.35s linear;
}
.postit__link:hover svg rect {
  stroke-width: 5;
  stroke-dasharray: 15, 308.5;
  stroke-dashoffset: 45;
  transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
}
.postit__inside {
  display: block;
  position: relative;
  padding: 25px 40px 25px 0;
  text-decoration: none;
  border-top: 2px solid #3a3a3a;
}
@media screen and (max-width: 959px) {
  .postit__inside {
    padding-left: 20px;
    padding-right: 20px;
    border-top-color: transparent;
  }
}
.postit__inside:hover {
  padding-left: 20px;
  padding-right: 20px;
  border-top-color: transparent;
}
.postit__inside--blue {
  background: linear-gradient(45deg, #a1faf9 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.postit__inside--blue:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.postit__inside--blue:hover {
  background-position: 0 calc(100% + 34px);
}
.postit__inside--blue:hover:before, .postit__inside--blue:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .postit__inside--blue:hover:before, .postit__inside--blue:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .postit__inside--blue {
    background-position: 0 calc(100% + 34px);
  }
  .postit__inside--blue:before, .postit__inside--blue:after {
    opacity: 1;
  }
}
.postit__inside--blue:before {
  top: -1.5px;
}
.postit__inside--yellow {
  background: linear-gradient(45deg, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.postit__inside--yellow:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.postit__inside--yellow:hover {
  background-position: 0 calc(100% + 34px);
}
.postit__inside--yellow:hover:before, .postit__inside--yellow:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .postit__inside--yellow:hover:before, .postit__inside--yellow:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .postit__inside--yellow {
    background-position: 0 calc(100% + 34px);
  }
  .postit__inside--yellow:before, .postit__inside--yellow:after {
    opacity: 1;
  }
}
.postit__inside--yellow:before {
  top: -1.5px;
}
.postit__inside--green {
  background: linear-gradient(45deg, #b2fbac 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.postit__inside--green:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.postit__inside--green:hover {
  background-position: 0 calc(100% + 34px);
}
.postit__inside--green:hover:before, .postit__inside--green:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .postit__inside--green:hover:before, .postit__inside--green:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .postit__inside--green {
    background-position: 0 calc(100% + 34px);
  }
  .postit__inside--green:before, .postit__inside--green:after {
    opacity: 1;
  }
}
.postit__inside--green:before {
  top: -1.5px;
}
.postit__inside--pink {
  background: linear-gradient(45deg, #fbacf6 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.postit__inside--pink:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.postit__inside--pink:hover {
  background-position: 0 calc(100% + 34px);
}
.postit__inside--pink:hover:before, .postit__inside--pink:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .postit__inside--pink:hover:before, .postit__inside--pink:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .postit__inside--pink {
    background-position: 0 calc(100% + 34px);
  }
  .postit__inside--pink:before, .postit__inside--pink:after {
    opacity: 1;
  }
}
.postit__inside--pink:before {
  top: -1.5px;
}

.tns-inner {
  overflow: hidden;
  max-width: calc(100vw - 20px);
}

.news-list {
  overflow: hidden;
  text-decoration: inherit;
}
.news-list__inside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 80vh;
  min-height: 400px;
  padding: 80px 40px 60px;
  font-size: 22px;
  line-height: 1.2;
  text-decoration: none;
}
.news-list h2 {
  margin: 30px 0 60px;
  transition: all 0.4s;
  font-size: 32px;
  font-weight: 900;
  text-transform: initial;
}
.news-list__link {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  transition: opacity 0.4s;
}
.news-list__link svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.news-list__link svg rect {
  fill: none;
  stroke: #3a3a3a;
  stroke-width: 5;
  stroke-dasharray: 422, 0;
  transition: all 0.35s linear;
}
.news-list__link:hover svg rect {
  stroke-width: 5;
  stroke-dasharray: 15, 209.5;
  stroke-dashoffset: 45;
  transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
}
@media screen and (min-width: 960px) {
  .news-list.tns-slide-active + .news-list > .news-list__inside {
    border-left: 1px solid rgba(58, 58, 58, 0.25);
  }
}
.layout--orange .news-list:nth-child(4n+1) .news-list__inside {
  background: linear-gradient(45deg, #a1faf9 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--orange .news-list:nth-child(4n+1) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--orange .news-list:nth-child(4n+1) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--orange .news-list:nth-child(4n+1) .news-list__inside:hover:before, .layout--orange .news-list:nth-child(4n+1) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+1) .news-list__inside:hover:before, .layout--orange .news-list:nth-child(4n+1) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+1) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--orange .news-list:nth-child(4n+1) .news-list__inside:before, .layout--orange .news-list:nth-child(4n+1) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--orange .news-list:nth-child(4n+1) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #fbacf6 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+1) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--orange .news-list:nth-child(4n+1) h2:hover {
  background-position: 0 0;
}
.layout--orange .news-list:nth-child(4n+2) .news-list__inside {
  background: linear-gradient(45deg, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--orange .news-list:nth-child(4n+2) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--orange .news-list:nth-child(4n+2) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--orange .news-list:nth-child(4n+2) .news-list__inside:hover:before, .layout--orange .news-list:nth-child(4n+2) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+2) .news-list__inside:hover:before, .layout--orange .news-list:nth-child(4n+2) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+2) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--orange .news-list:nth-child(4n+2) .news-list__inside:before, .layout--orange .news-list:nth-child(4n+2) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--orange .news-list:nth-child(4n+2) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #a1faf9 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+2) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--orange .news-list:nth-child(4n+2) h2:hover {
  background-position: 0 0;
}
.layout--orange .news-list:nth-child(4n+3) .news-list__inside {
  background: linear-gradient(45deg, #b2fbac 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--orange .news-list:nth-child(4n+3) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--orange .news-list:nth-child(4n+3) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--orange .news-list:nth-child(4n+3) .news-list__inside:hover:before, .layout--orange .news-list:nth-child(4n+3) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+3) .news-list__inside:hover:before, .layout--orange .news-list:nth-child(4n+3) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+3) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--orange .news-list:nth-child(4n+3) .news-list__inside:before, .layout--orange .news-list:nth-child(4n+3) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--orange .news-list:nth-child(4n+3) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+3) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--orange .news-list:nth-child(4n+3) h2:hover {
  background-position: 0 0;
}
.layout--orange .news-list:nth-child(4n+4) .news-list__inside {
  background: linear-gradient(45deg, #fbacf6 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--orange .news-list:nth-child(4n+4) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--orange .news-list:nth-child(4n+4) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--orange .news-list:nth-child(4n+4) .news-list__inside:hover:before, .layout--orange .news-list:nth-child(4n+4) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+4) .news-list__inside:hover:before, .layout--orange .news-list:nth-child(4n+4) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+4) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--orange .news-list:nth-child(4n+4) .news-list__inside:before, .layout--orange .news-list:nth-child(4n+4) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--orange .news-list:nth-child(4n+4) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #b2fbac 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--orange .news-list:nth-child(4n+4) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--orange .news-list:nth-child(4n+4) h2:hover {
  background-position: 0 0;
}
.layout--blue .news-list:nth-child(3n+1) .news-list__inside {
  background: linear-gradient(45deg, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--blue .news-list:nth-child(3n+1) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--blue .news-list:nth-child(3n+1) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--blue .news-list:nth-child(3n+1) .news-list__inside:hover:before, .layout--blue .news-list:nth-child(3n+1) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--blue .news-list:nth-child(3n+1) .news-list__inside:hover:before, .layout--blue .news-list:nth-child(3n+1) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--blue .news-list:nth-child(3n+1) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--blue .news-list:nth-child(3n+1) .news-list__inside:before, .layout--blue .news-list:nth-child(3n+1) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--blue .news-list:nth-child(3n+1) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #a1faf9 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--blue .news-list:nth-child(3n+1) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--blue .news-list:nth-child(3n+1) h2:hover {
  background-position: 0 0;
}
.layout--blue .news-list:nth-child(3n+2) .news-list__inside {
  background: linear-gradient(45deg, #b2fbac 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--blue .news-list:nth-child(3n+2) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--blue .news-list:nth-child(3n+2) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--blue .news-list:nth-child(3n+2) .news-list__inside:hover:before, .layout--blue .news-list:nth-child(3n+2) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--blue .news-list:nth-child(3n+2) .news-list__inside:hover:before, .layout--blue .news-list:nth-child(3n+2) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--blue .news-list:nth-child(3n+2) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--blue .news-list:nth-child(3n+2) .news-list__inside:before, .layout--blue .news-list:nth-child(3n+2) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--blue .news-list:nth-child(3n+2) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--blue .news-list:nth-child(3n+2) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--blue .news-list:nth-child(3n+2) h2:hover {
  background-position: 0 0;
}
.layout--blue .news-list:nth-child(3n+3) .news-list__inside {
  background: linear-gradient(45deg, #fbacf6 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--blue .news-list:nth-child(3n+3) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--blue .news-list:nth-child(3n+3) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--blue .news-list:nth-child(3n+3) .news-list__inside:hover:before, .layout--blue .news-list:nth-child(3n+3) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--blue .news-list:nth-child(3n+3) .news-list__inside:hover:before, .layout--blue .news-list:nth-child(3n+3) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--blue .news-list:nth-child(3n+3) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--blue .news-list:nth-child(3n+3) .news-list__inside:before, .layout--blue .news-list:nth-child(3n+3) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--blue .news-list:nth-child(3n+3) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #b2fbac 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--blue .news-list:nth-child(3n+3) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--blue .news-list:nth-child(3n+3) h2:hover {
  background-position: 0 0;
}
.layout--yellow .news-list:nth-child(3n+1) .news-list__inside {
  background: linear-gradient(45deg, #a1faf9 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--yellow .news-list:nth-child(3n+1) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--yellow .news-list:nth-child(3n+1) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--yellow .news-list:nth-child(3n+1) .news-list__inside:hover:before, .layout--yellow .news-list:nth-child(3n+1) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--yellow .news-list:nth-child(3n+1) .news-list__inside:hover:before, .layout--yellow .news-list:nth-child(3n+1) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--yellow .news-list:nth-child(3n+1) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--yellow .news-list:nth-child(3n+1) .news-list__inside:before, .layout--yellow .news-list:nth-child(3n+1) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--yellow .news-list:nth-child(3n+1) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #fbacf6 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--yellow .news-list:nth-child(3n+1) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--yellow .news-list:nth-child(3n+1) h2:hover {
  background-position: 0 0;
}
.layout--yellow .news-list:nth-child(3n+2) .news-list__inside {
  background: linear-gradient(45deg, #b2fbac 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--yellow .news-list:nth-child(3n+2) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--yellow .news-list:nth-child(3n+2) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--yellow .news-list:nth-child(3n+2) .news-list__inside:hover:before, .layout--yellow .news-list:nth-child(3n+2) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--yellow .news-list:nth-child(3n+2) .news-list__inside:hover:before, .layout--yellow .news-list:nth-child(3n+2) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--yellow .news-list:nth-child(3n+2) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--yellow .news-list:nth-child(3n+2) .news-list__inside:before, .layout--yellow .news-list:nth-child(3n+2) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--yellow .news-list:nth-child(3n+2) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--yellow .news-list:nth-child(3n+2) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--yellow .news-list:nth-child(3n+2) h2:hover {
  background-position: 0 0;
}
.layout--yellow .news-list:nth-child(3n+3) .news-list__inside {
  background: linear-gradient(45deg, #fbacf6 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--yellow .news-list:nth-child(3n+3) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--yellow .news-list:nth-child(3n+3) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--yellow .news-list:nth-child(3n+3) .news-list__inside:hover:before, .layout--yellow .news-list:nth-child(3n+3) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--yellow .news-list:nth-child(3n+3) .news-list__inside:hover:before, .layout--yellow .news-list:nth-child(3n+3) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--yellow .news-list:nth-child(3n+3) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--yellow .news-list:nth-child(3n+3) .news-list__inside:before, .layout--yellow .news-list:nth-child(3n+3) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--yellow .news-list:nth-child(3n+3) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #b2fbac 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--yellow .news-list:nth-child(3n+3) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--yellow .news-list:nth-child(3n+3) h2:hover {
  background-position: 0 0;
}
.layout--green .news-list:nth-child(3n+1) .news-list__inside {
  background: linear-gradient(45deg, #a1faf9 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--green .news-list:nth-child(3n+1) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--green .news-list:nth-child(3n+1) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--green .news-list:nth-child(3n+1) .news-list__inside:hover:before, .layout--green .news-list:nth-child(3n+1) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--green .news-list:nth-child(3n+1) .news-list__inside:hover:before, .layout--green .news-list:nth-child(3n+1) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--green .news-list:nth-child(3n+1) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--green .news-list:nth-child(3n+1) .news-list__inside:before, .layout--green .news-list:nth-child(3n+1) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--green .news-list:nth-child(3n+1) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #fbacf6 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--green .news-list:nth-child(3n+1) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--green .news-list:nth-child(3n+1) h2:hover {
  background-position: 0 0;
}
.layout--green .news-list:nth-child(3n+2) .news-list__inside {
  background: linear-gradient(45deg, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--green .news-list:nth-child(3n+2) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--green .news-list:nth-child(3n+2) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--green .news-list:nth-child(3n+2) .news-list__inside:hover:before, .layout--green .news-list:nth-child(3n+2) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--green .news-list:nth-child(3n+2) .news-list__inside:hover:before, .layout--green .news-list:nth-child(3n+2) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--green .news-list:nth-child(3n+2) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--green .news-list:nth-child(3n+2) .news-list__inside:before, .layout--green .news-list:nth-child(3n+2) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--green .news-list:nth-child(3n+2) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #a1faf9 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--green .news-list:nth-child(3n+2) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--green .news-list:nth-child(3n+2) h2:hover {
  background-position: 0 0;
}
.layout--green .news-list:nth-child(3n+3) .news-list__inside {
  background: linear-gradient(45deg, #fbacf6 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--green .news-list:nth-child(3n+3) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--green .news-list:nth-child(3n+3) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--green .news-list:nth-child(3n+3) .news-list__inside:hover:before, .layout--green .news-list:nth-child(3n+3) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--green .news-list:nth-child(3n+3) .news-list__inside:hover:before, .layout--green .news-list:nth-child(3n+3) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--green .news-list:nth-child(3n+3) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--green .news-list:nth-child(3n+3) .news-list__inside:before, .layout--green .news-list:nth-child(3n+3) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--green .news-list:nth-child(3n+3) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #b2fbac 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--green .news-list:nth-child(3n+3) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--green .news-list:nth-child(3n+3) h2:hover {
  background-position: 0 0;
}
.layout--pink .news-list:nth-child(3n+1) .news-list__inside {
  background: linear-gradient(45deg, #a1faf9 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--pink .news-list:nth-child(3n+1) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--pink .news-list:nth-child(3n+1) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--pink .news-list:nth-child(3n+1) .news-list__inside:hover:before, .layout--pink .news-list:nth-child(3n+1) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--pink .news-list:nth-child(3n+1) .news-list__inside:hover:before, .layout--pink .news-list:nth-child(3n+1) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--pink .news-list:nth-child(3n+1) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--pink .news-list:nth-child(3n+1) .news-list__inside:before, .layout--pink .news-list:nth-child(3n+1) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--pink .news-list:nth-child(3n+1) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #fbacf6 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--pink .news-list:nth-child(3n+1) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--pink .news-list:nth-child(3n+1) h2:hover {
  background-position: 0 0;
}
.layout--pink .news-list:nth-child(3n+2) .news-list__inside {
  background: linear-gradient(45deg, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--pink .news-list:nth-child(3n+2) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--pink .news-list:nth-child(3n+2) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--pink .news-list:nth-child(3n+2) .news-list__inside:hover:before, .layout--pink .news-list:nth-child(3n+2) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--pink .news-list:nth-child(3n+2) .news-list__inside:hover:before, .layout--pink .news-list:nth-child(3n+2) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--pink .news-list:nth-child(3n+2) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--pink .news-list:nth-child(3n+2) .news-list__inside:before, .layout--pink .news-list:nth-child(3n+2) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--pink .news-list:nth-child(3n+2) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #a1faf9 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--pink .news-list:nth-child(3n+2) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--pink .news-list:nth-child(3n+2) h2:hover {
  background-position: 0 0;
}
.layout--pink .news-list:nth-child(3n+3) .news-list__inside {
  background: linear-gradient(45deg, #b2fbac 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: 0.5s ease-in-out;
}
.layout--pink .news-list:nth-child(3n+3) .news-list__inside:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid rgba(58, 58, 58, 0.1);
}
.layout--pink .news-list:nth-child(3n+3) .news-list__inside:hover {
  background-position: 0 calc(100% + 34px);
}
.layout--pink .news-list:nth-child(3n+3) .news-list__inside:hover:before, .layout--pink .news-list:nth-child(3n+3) .news-list__inside:hover:after {
  animation: 0.4s postit-corner forwards;
}
@media screen and (max-width: 959px) {
  .layout--pink .news-list:nth-child(3n+3) .news-list__inside:hover:before, .layout--pink .news-list:nth-child(3n+3) .news-list__inside:hover:after {
    animation: none;
  }
}
@media screen and (max-width: 959px) {
  .layout--pink .news-list:nth-child(3n+3) .news-list__inside {
    background-position: 0 calc(100% + 34px);
  }
  .layout--pink .news-list:nth-child(3n+3) .news-list__inside:before, .layout--pink .news-list:nth-child(3n+3) .news-list__inside:after {
    opacity: 1;
  }
}
.layout--pink .news-list:nth-child(3n+3) h2 {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--pink .news-list:nth-child(3n+3) h2 {
    background-position: 0.8em 0 !important;
  }
}
.layout--pink .news-list:nth-child(3n+3) h2:hover {
  background-position: 0 0;
}
.news-details__date {
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
}
.news-details__headline {
  margin: 0.5em 0 0.5em !important;
}
.news-details__author a {
  font-size: 18px;
  text-decoration: none;
}
.news-details__categories {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.news-details__categories li {
  margin: 0;
  padding: 0;
}
.news-details__categories a {
  font-size: 18px;
  text-decoration: none;
}
.news-details__categories a:before {
  content: "#";
}
.news-details__social {
  margin: 25px 0 60px;
  padding: 0;
  list-style-type: none;
}
.news-details__social-item {
  display: inline;
  margin: 0 30px 0 0;
  padding: 0;
}
.news-details__social-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: left;
  text-indent: -999em;
  background: center bottom no-repeat;
  background-size: contain;
}
.news-details__social-icon--facebook {
  background-image: url("../images/icon__facebook.svg");
}
.news-details__social-icon--linkedin {
  background-image: url("../images/icon__linkedin.svg");
}
.news-details__social-icon--linkedin-dark {
  background-image: url("../images/icon__linkedin--dark.svg");
}
.news-details__social-icon--email {
  background-image: url("../images/icon__email.svg");
}
.news-details__social-icon--print {
  background-image: url("../images/icon__print.svg");
}
.news-details__social-icon--twitter {
  background-image: url("../images/icon__twitter.svg");
}
.news-details__social-icon--xing {
  background-image: url("../images/icon__xing.svg");
}
.news-details__text-inside {
  hyphens: auto;
  border-top: 2px solid #3a3a3a;
}
.news-details__text .content-text:first-child {
  margin-bottom: 2em;
  font-size: 23px;
  line-height: 1.3;
  font-weight: 400;
}
.news-details__text .content-text:first-child b,
.news-details__text .content-text:first-child strong {
  font-weight: 900;
}
@media screen and (min-width: 960px) {
  .news-details__header {
    text-align: right;
  }
  .news-details__social {
    margin: 60px 0 0;
    text-align: right;
  }
  .news-details__social-item {
    margin: 0 0 0 30px;
  }
  .news-details__social-icon {
    width: 15px;
    height: 15px;
  }
  .news-details__text-inside {
    padding-top: 18px;
  }
}

.topic-navigation {
  margin-top: 40px;
}
.topic-navigation li {
  margin: 0 0 10px 25px;
  line-height: 1.45;
}
.topic-navigation a {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 2px;
  text-decoration: none;
}
.layout--orange .topic-navigation a {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #b2fbac 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--orange .topic-navigation a {
    background-position: 0.8em 0 !important;
  }
}
.layout--orange .topic-navigation a:hover {
  background-position: 0 0;
}
.layout--blue .topic-navigation a {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #fbacf6 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--blue .topic-navigation a {
    background-position: 0.8em 0 !important;
  }
}
.layout--blue .topic-navigation a:hover {
  background-position: 0 0;
}
.layout--yellow .topic-navigation a {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #a1faf9 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--yellow .topic-navigation a {
    background-position: 0.8em 0 !important;
  }
}
.layout--yellow .topic-navigation a:hover {
  background-position: 0 0;
}
.layout--green .topic-navigation a {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--green .topic-navigation a {
    background-position: 0.8em 0 !important;
  }
}
.layout--green .topic-navigation a:hover {
  background-position: 0 0;
}
.layout--pink .topic-navigation a {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #b2fbac 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--pink .topic-navigation a {
    background-position: 0.8em 0 !important;
  }
}
.layout--pink .topic-navigation a:hover {
  background-position: 0 0;
}
.layout--violet .topic-navigation a {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #e9fe5f 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--violet .topic-navigation a {
    background-position: 0.8em 0 !important;
  }
}
.layout--violet .topic-navigation a:hover {
  background-position: 0 0;
}
.layout--neon .topic-navigation a {
  margin-right: -0.8em;
  padding: 2px 0.8em 2px 0;
  background: linear-gradient(to right, #cb66ff 50%, rgba(255, 255, 255, 0) 50%) 100% 0 no-repeat;
  background-size: 200%;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 959px) {
  .layout--neon .topic-navigation a {
    background-position: 0.8em 0 !important;
  }
}
.layout--neon .topic-navigation a:hover {
  background-position: 0 0;
}
.topic-navigation .subtitle {
  display: block;
  padding: 0 15px 0 12px;
  font-size: 15px;
}
@media screen and (min-width: 960px) {
  .topic-navigation {
    text-align: right;
  }
}

.team-header:after {
  content: "";
  display: table;
  clear: both;
}
.team-header h1 {
  display: inline-block;
  width: min-content;
  margin-bottom: 20px;
  line-height: 1;
}
.team-header p {
  float: left;
  margin: 0;
  font-size: 18px;
  line-height: 27px;
}
.team-header ul, .team-header li {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.team-header img {
  width: 100%;
}
.team-header__left {
  margin-bottom: 15px;
}
.team-header__left:after {
  content: "";
  display: table;
  clear: both;
}
.team-header__right {
  margin-right: -20px;
}
.team-header__social {
  float: right;
}
.team-header__social-icon {
  margin: 0 0 0 20px;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: left;
  text-indent: -999em;
  background: center bottom no-repeat;
  background-size: contain;
}
.team-header__social-icon--email {
  width: 25px;
}
.team-header__social-icon--facebook {
  background-image: url("../images/icon__facebook.svg");
}
.team-header__social-icon--linkedin {
  background-image: url("../images/icon__linkedin.svg");
}
.team-header__social-icon--linkedin-dark {
  background-image: url("../images/icon__linkedin--dark.svg");
}
.team-header__social-icon--email {
  background-image: url("../images/icon__email.svg");
}
.team-header__social-icon--print {
  background-image: url("../images/icon__print.svg");
}
.team-header__social-icon--twitter {
  background-image: url("../images/icon__twitter.svg");
}
.team-header__social-icon--xing {
  background-image: url("../images/icon__xing.svg");
}
@media screen and (min-width: 960px) {
  .team-header p {
    float: none;
  }
  .team-header__left {
    text-align: right;
  }
  .team-header__right {
    margin-right: 0;
  }
  .team-header__social {
    float: none;
    margin-top: 50px !important;
  }
}

.mod_team_topics {
  margin: 0 0 50px;
  font-size: 20px;
  line-height: 1.2;
}
.mod_team_topics li {
  padding: 3px 0;
}
.mod_team_topics a {
  text-decoration: none;
}
.mod_team_topics a:hover {
  text-decoration: underline;
}
.mod_team_topics a:before {
  content: "#";
}
@media screen and (min-width: 960px) {
  .mod_team_topics {
    margin: 110px 0 0;
    font-size: 18px;
    text-align: right;
  }
}

.mod_topic_team h2 {
  margin: 3em 0 1em;
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
}
.mod_topic_team li {
  padding: 5px 0;
}

.social-element__icon {
  width: 20px;
  height: 20px;
  margin-left: 25px;
  display: inline-block;
  text-align: left;
  text-indent: -999em;
  background: center bottom no-repeat;
  background-size: contain;
}
.social-element__icon--facebook {
  background-image: url("../images/icon__facebook.svg");
}
.social-element__icon--linkedin {
  background-image: url("../images/icon__linkedin.svg");
}
.social-element__icon--linkedin-dark {
  background-image: url("../images/icon__linkedin--dark.svg");
}
.social-element__icon--email {
  background-image: url("../images/icon__email.svg");
}
.social-element__icon--print {
  background-image: url("../images/icon__print.svg");
}
.social-element__icon--twitter {
  background-image: url("../images/icon__twitter.svg");
}
.social-element__icon--xing {
  background-image: url("../images/icon__xing.svg");
}

.current-page {
  margin: 15px 0 20px;
  font-size: 20px;
  font-weight: 700;
}
@media screen and (min-width: 960px) {
  .current-page {
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    width: 33.3333%;
    margin: 0;
    padding: 0 40px;
    line-height: 1;
    text-align: right;
  }
}

.page-navigation,
.news-navigation {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 50px;
  padding: 0;
  font-weight: 300;
  line-height: 1.125;
}
.page-navigation a,
.news-navigation a {
  position: relative;
  display: inline-block;
  padding: 0 12px;
  text-decoration: none;
}
.page-navigation strong,
.page-navigation time,
.news-navigation strong,
.news-navigation time {
  font-weight: 900;
}
.layout--team .page-navigation strong,
.layout--team .page-navigation time,
.layout--team .news-navigation strong,
.layout--team .news-navigation time {
  display: none;
}
.page-navigation__prev, .page-navigation__next,
.news-navigation__prev,
.news-navigation__next {
  flex-grow: 1;
  width: 50%;
}
.page-navigation__prev,
.news-navigation__prev {
  text-align: left;
}
.page-navigation__prev a:before,
.news-navigation__prev a:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "< ";
}
.page-navigation__next,
.news-navigation__next {
  text-align: right;
}
.page-navigation__next a:after,
.news-navigation__next a:after {
  position: absolute;
  top: 0;
  right: 0;
  content: " >";
}
@media screen and (min-width: 960px) {
  .page-navigation,
  .news-navigation {
    position: absolute;
    top: -100px;
    left: 20px;
    right: 20px;
    font-weight: 300;
  }
}

.page-navigation span {
  display: block;
  overflow: hidden;
}
.page-navigation__prev strong {
  float: left;
  padding-right: 5px;
}
.page-navigation__next strong {
  float: right;
  padding-left: 5px;
}

.news-navigation time {
  display: block;
}
.news-navigation span {
  display: block;
  height: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (min-width: 960px) {
  .news-navigation__prev time {
    float: left;
    padding-right: 5px;
  }
  .news-navigation__next time {
    float: right;
    padding-left: 5px;
  }
}

.swipe-icon {
  display: block;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.swipe-icon__svg {
  width: 72px;
  height: 35px;
}
.swipe-icon__hand {
  fill: #3a3a3a;
  transform-origin: 50% 100%;
  animation: swipe-icon 3.5s infinite ease;
}
.swipe-icon__left, .swipe-icon__right {
  fill: #3a3a3a;
  opacity: 0;
}
.swipe-icon__right {
  animation: swipe-icon-right 3.5s infinite ease;
}
.swipe-icon__left {
  animation: swipe-icon-left 3.5s infinite ease;
}
@keyframes swipe-icon {
  5% {
    transform: translateX(3px) rotate(6deg);
  }
  25% {
    transform: translateX(0px) rotate(0deg);
  }
  28% {
    transform: translateX(0px) rotate(0deg);
  }
  33% {
    transform: translateX(-3px) rotate(-6deg);
  }
  53% {
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    transform: translateX(0px) rotate(0deg);
  }
}
@keyframes swipe-icon-right {
  7% {
    transform: translateX(8px);
    opacity: 1;
  }
  17% {
    transform: translateX(10px);
    opacity: 0;
  }
  18%, 100% {
    transform: translateX(0);
    opacity: 0;
  }
}
@keyframes swipe-icon-left {
  0%, 30% {
    transform: translateX(0);
    opacity: 0;
  }
  37% {
    transform: translateX(-8px);
    opacity: 1;
  }
  47% {
    transform: translateX(-10px);
    opacity: 0;
  }
  48%, 100% {
    transform: translateX(0);
    opacity: 0;
  }
}

$font-weight: (
    light: 300,
    regular: 400,
    bold: 700,
    black: 900,
);

$colors: (
    orange: #f4a100,
    blue: #a1faf9,
    yellow: #e9fe5f,
    green: #b2fbac,
    pink: #fbacf6,
    violet: #cb66ff,
    grey: #e0e0e0,
    text: #3a3a3a,
    neon: #ffcc02,
);

$contrasts: (
    orange: map-get($colors, green),
    blue: map-get($colors, pink),
    yellow: map-get($colors, blue),
    green: map-get($colors, yellow),
    pink: map-get($colors, green),
    violet: map-get($colors, yellow),
    neon: map-get($colors, violet),
);

$text-color: map-get($colors, text);
$link-color: map-get($colors, text);

@import "mixins";
@import "layout";
@import "video";
@import "grid";
@import "header";
@import "footer";
@import "layouts/generic";
@import "layouts/home";
@import "layouts/navigation";
@import "layouts/topic";
@import "layouts/team";
@import "layouts/content";
@import "layouts/blog";
@import "fragments/main-navigation";
@import "fragments/postit";
@import "fragments/news-list";
@import "fragments/news-details";
@import "fragments/topic-navigation";
@import "fragments/team";
@import "fragments/elements";
@import "fragments/swipe-icon";

.topic-navigation {
    margin-top: 40px;

    li {
        margin: 0 0 10px 25px;
        line-height: 1.45;
    }

    a {
        font-size: 20px;
        font-weight: map-get($font-weight, light);
        letter-spacing: 2px;
        text-decoration: none;

        @include highlight-contrast(true);
    }

    .subtitle {
        display: block;
        padding: 0 15px 0 12px;
        font-size: 15px;
    }

    @include screen(medium) {
        text-align: right;
    }
}


@mixin font-regular() {
    font-weight: map-get($font-weight, regular);

    b,
    strong {
        font-weight: map-get($font-weight, black);
    }
}

@mixin screen($value, $prop: 'min-width', $hover: false) {
    $width: $value;
    $sizes: (
        small: 600px,
        medium: 960px,
        large: 1200px,
        ultra: 1900px,
    );

    @if (map_has_key($sizes, $value)) {
        $width: map_get($sizes, $value);

        @if ($prop == 'max-width') {
            $width: $width - 1;
        }
    }

    @media screen and (#{$prop}: $width) {
        @content;
    }

    @if ($hover) {
        &:hover {
            @content;
        }
    }
}

@mixin highlight($color, $activeOnMobile: false) {
    margin-right: -.8em;
    padding: 2px .8em 2px 0;
    background: linear-gradient(to right, $color 50%, rgba(255,255,255,0) 50%) 100% 0 no-repeat;
    background-size: 200%;
    transition: .5s ease-in-out;

    @if ($activeOnMobile) {
        @include screen(medium, max-width) {
            background-position: .8em 0 !important;
        }
    }

    &:hover {
        background-position: 0 0;
    }
}

@mixin highlight-contrast($activeOnMobile: false) {
    @each $color, $contrast in $contrasts {
        .layout--#{$color} & {
            @include highlight($contrast, $activeOnMobile);
        }
    }
}

@mixin clear-after() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin social-icons() {
    display: inline-block;
    text-align: left;
    text-indent: -999em;
    background: center bottom no-repeat;
    background-size: contain;

    &--facebook {
        background-image: url("../images/icon__facebook.svg");
    }

    &--linkedin {
        background-image: url("../images/icon__linkedin.svg");
    }

    &--linkedin-dark {
        background-image: url("../images/icon__linkedin--dark.svg");
    }

    &--email {
        background-image: url("../images/icon__email.svg");
    }

    &--print {
        background-image: url("../images/icon__print.svg");
    }

    &--twitter {
        background-image: url("../images/icon__twitter.svg");
    }

    &--xing {
        background-image: url("../images/icon__xing.svg");
    }
}

@mixin postit($color) {
    background: linear-gradient(45deg, map-get($colors, $color) 50%, rgba(255, 255, 255, 0) 50%);
    background-size: 200% 200%;
    background-position: 100% 0;
    transition: .5s ease-in-out;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        border-top: 35px solid transparent;
        border-left: 35px solid fade_out(map-get($colors, text), .9);
    }

    &:hover {
        background-position: 0 calc(100% + 34px);

        &:before,
        &:after {
            animation: .4s postit-corner forwards;

            @include screen(medium, max-width) {
                animation: none;
            }
        }
    }

    @include screen(medium, max-width) {
        background-position: 0 calc(100% + 34px);

        &:before,
        &:after {
            opacity: 1;
        }
    }
}

@mixin link-button($width, $height, $stroke-width: 15) {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: $width * 1px;
    height: $height * 1px;
    line-height: $height * 1px;
    text-align: center;
    text-decoration: none;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    svg rect {
        fill: none;
        stroke: $text-color;
        stroke-width: 5;
        stroke-dasharray: 422, 0;
        transition: all 0.35s linear;
    }

    &:hover svg rect {
        stroke-width: 5;
        stroke-dasharray: $stroke-width, $width + $height + $width / 2 - $stroke-width / 2 + 30;
        stroke-dashoffset: $stroke-width + 30;
        transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
}

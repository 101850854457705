.layout--home  {

    &:before {
        content: "";
        position: absolute;
        width: 150vw;
        height: 150vw;
        z-index: -1;
        background: linear-gradient(45deg, map-get($colors, orange) 50%, map-get($colors, gray) 50%);
        background-size: 150vw 150vw;
        background-position: 0 0;
        left: -50vw;
        top: calc(-150vw + 140px);
        animation: corner-small-home 1.5s ease-in-out .5s forwards;

        // Fix for Edge – does not support calc() in animation
        @supports (-ms-ime-align:auto) {
            animation: corner-small-edge 1.5s ease-in-out .5s forwards;
        }

        @keyframes corner-small-home {
            0% {
                top: calc(-150vw + 140px);
            }
            100% {
                top: calc(-50vw + 85px);
            }
        }

        @keyframes corner-medium-home {
            0% {
                top: calc(-150vw + 140px);
            }
            100% {
                top: calc(-100vw + 250px);
            }
        }

        @keyframes corner-small-edge {
            0% {
                transform: translateY(0);
            }
            100% {
                transform: translateY(70%);
            }
        }

        @keyframes corner-medium-edge {
            0% {
                transform: translateY(0);
            }
            100% {
                transform: translateY(42%);
            }
        }

        @include screen(landscape, orientation) {
            top: calc(-150vw + 140px);
            animation: corner-medium-home 1.5s ease-in-out .5s forwards;

            // Fix for Edge – does not support calc() in animation
            @supports (-ms-ime-align:auto) {
                animation: corner-medium-edge 1.5s ease-in-out .5s forwards;
            }
        }

        @include screen(medium) {
            top: calc(-150vw + 140px);
            animation: corner-medium-home 1.5s ease-in-out .5s forwards;

            // Fix for Edge – does not support calc() in animation
            @supports (-ms-ime-align:auto) {
                animation: corner-medium-edge 1.5s ease-in-out .5s forwards;
            }
        }
    }

    .section-header {
        position: relative;
        min-height: 100vh;

        &__logo {
            opacity: 0;
            animation: logo-home .25s ease-in-out 1.25s forwards;

            @keyframes logo-home {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }

        &__scroll {
            position: absolute;
            left: 50%;
            bottom: 20vh;
            transform: translate(-50%,-50%);
            font-size: 14px;
            font-weight: map-get($font-weight, bold);
            color: map-get($colors, neon);
            text-transform: uppercase;
            opacity: 0;
            animation: scroll-home .25s ease-in-out 2.5s forwards;

            @include screen(medium) {
                bottom: 10vh;
            }

            &::before {
                display: block;
                position: absolute;
                top: 30px;
                left: 50%;
                width: 2px;
                height: 39px;
                margin-left: -1px;
                background: $text-color;
                content: "";
            }

            &::after {
                display: block;
                position: absolute;
                top: 30px;
                left: 50%;
                width: 2px;
                height: 13px;
                margin-left: -1px;
                background: map-get($colors, neon);
                content: "";
                animation-name: vertical-scroll;
                animation-duration: 2s;
                animation-iteration-count: infinite;
                animation-delay: 3s;
            }

            @keyframes scroll-home {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

            @keyframes vertical-scroll {
                0% {
                    height: 13px;
                    opacity: 1;
                }
                20% {
                    height: 13px;
                }
                30% {
                    transform: translateY(0);
                    height: 40px;
                }
                80% {
                    transform: translateY(40px);
                    height: 0;
                }
                81% {
                    transform: translateY(0);
                    height: 0;
                }
                100% {
                    height: 13px;
                }
            }
        }

        h1 {
            position: absolute;
            top: 70vh;
            width: 100%;
            transform: translateY(-50%);
            font-size: calc(4vw + 5vh);
            line-height: 1;
            text-align: center;
            text-transform: uppercase;
            opacity: 0;
            animation: title-small-home 1.5s ease-out .5s forwards;

            @keyframes title-small-home {
                0% {
                    opacity: 0;
                    top: 70vh;
                }
                100% {
                    opacity: 1;
                    top: 40vh;
                }
            }
        }

        @include screen(medium) {

            h1 {
                padding-right: 40%;
                font-size: calc(8vw);
                text-align: right;
                animation: title-medium-home 1.5s ease-out .5s forwards;

                @keyframes title-medium-home {
                    0% {
                        opacity: 0;
                        top: 70vh;
                    }
                    100% {
                        opacity: 1;
                        top: 50vh;
                    }
                }
            }
        }
    }

    .section-main {
        &__inside {
            &.animated {
                opacity: .2;
                transform: translateY(80px);

                &.entered {
                    opacity: 1;
                    transform: translateY(0);
                    transition: all 1s ease-out;
                }
            }

            > .mod_article.first {
                margin-top: -1em;
            }
        }

        .content-text {
            font-size: 20px;
            @include font-regular;
            line-height: 1.5;

            + .content-text {
                max-width: none;
                font-size: 45px;
                font-weight: map-get($font-weight, black);
                line-height: 1;
                text-align: center;
            }
        }

        @include screen(medium) {
            .content-text {
                max-width: 500px;
                margin: 0 auto;

                + .content-text {
                    font-size: 75px;
                }
            }
        }
    }

    .section-wir-machen {

        h1 {
            margin: 0 0 -20px;
            font-size: 65px;
            line-height: 60px;
        }

        @include screen(small) {
            .mod_article {
                @include grid-row;

                h1 {
                    margin: 0 0 -20px 20px;
                }

                > .postit {
                    @include grid-col(50%);
                }
            }
        }

        @include screen(medium) {
            max-width: 1140px;
            margin: 250px auto 0;

            .mod_article {
                h1 {
                    margin: -115px 0 0;
                    font-size: 75px;
                    line-height: 1;
                    text-align: right;
                    word-spacing: 100vw;
                }

                h1,
                > .postit {
                    @include grid-col(33.33333333%);
                }
            }
        }
    }

    .section-blog {
        h1 {
            position: relative;
            margin: 0 0 -120px;
            font-size: 65px;
            line-height: 60px;
            z-index: 10;
        }

        @include screen(medium) {
            h1 {
                font-size: 75px;
                line-height: 1;
                margin: 0 0 -150px 20%;
            }
        }

        //@include screen(large) {
        //    margin-left: 20px;
        //    margin-right: 20px;
        //}
    }
}

.layout--navigation {

    .layout__columns {
        max-width: none;
    }

    .section-main {
        &__inside {
            max-width: 600px;
            margin: 0 auto;
        }

        h1 {
            margin: 0;
            font-size: 65px;
            line-height: 60px;
            text-transform: uppercase;
        }
    }

    @include screen(medium) {
        .section-header {
            height: 50vw;
            max-height: 200px;
        }

        .section-main {
            text-align: right;

            &__inside {
                margin: 0 50% 0 auto;
            }

            h1 {
                font-size: 75px;
                line-height: 80px;
            }
        }
    }
}

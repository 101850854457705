
.team-header {
    @include clear-after;

    h1 {
        display: inline-block;
        width: min-content;
        margin-bottom: 20px;
        line-height: 1;
    }

    p {
        float: left;
        margin: 0;
        font-size: 18px;
        line-height: 27px;
    }

    ul, li {
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    img {
        width: 100%;
    }

    &__left {
        margin-bottom: 15px;
        @include clear-after;
    }

    &__right {
        margin-right: -20px;
    }

    &__social {
        float: right;

        &-icon {
            margin: 0 0 0 20px;
            width: 20px;
            height: 20px;

            &--email {
                width: 25px;
            }

            @include social-icons;
        }
    }

    @include screen(medium) {
        p {
            float: none;
        }

        &__left {
            text-align: right;
        }

        &__right {
            margin-right: 0;
        }

        &__social {
            float: none;
            margin-top: 50px !important;
        }
    }
}

.mod_team_topics {
    margin: 0 0 50px;
    font-size: 20px;
    line-height: 1.2;

    li {
        padding: 3px 0;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &:before {
            content: "#";
        }
    }

    @include screen(medium) {
        margin: 110px 0 0;
        font-size: 18px;
        text-align: right;
    }

}

.mod_topic_team {
    h2 {
        margin: 3em 0 1em;
        font-size: 18px;
        font-weight: map-get($font-weight, black);
        text-transform: uppercase;
    }

    li {
        padding: 5px 0;
    }
}

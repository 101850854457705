.layout--topic {

    .section-main {
        hyphens: auto;

        &__inside {
            border-top: 2px solid map-get($colors, text);
            padding-top: 1.3em;
        }
    }

    .mod_topic_news {
        display: flex;
        justify-content: stretch;

        .news-list {
            &__inside {
                height: 100%;
            }
        }
    }

    @include screen(medium) {
        .section-left {
            text-align: right;

            h1 {
                margin-top: 60px;
            }
        }

        .section-right {
            &__inside {
                border-top: 2px solid map-get($colors, text);
            }
        }

        .section-main {
            &__inside {
                padding-top: 2em;
            }
        }
    }
}

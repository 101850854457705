.swipe-icon {
    display: block;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;

    &__svg {
        width: 72px;
        height: 35px;
    }

    &__hand {
        fill: $text-color;
        transform-origin: 50% 100%;
        animation: swipe-icon 3.5s infinite ease;
    }

    &__left,
    &__right {
        fill: $text-color;
        opacity: 0;
    }

    &__right {
        animation: swipe-icon-right 3.5s infinite ease;
    }

    &__left {
        animation: swipe-icon-left 3.5s infinite ease;
    }

    @keyframes swipe-icon {
        5% { transform: translateX(3px) rotate(6deg); }
        25% { transform: translateX(0px) rotate(0deg); }
        28% { transform: translateX(0px) rotate(0deg); }
        33% { transform: translateX(-3px) rotate(-6deg); }
        53% { transform: translateX(0px) rotate(0deg); }
        100% { transform: translateX(0px) rotate(0deg); }
    }

    @keyframes swipe-icon-right {
        7% { transform: translateX(8px); opacity: 1; }
        17% { transform: translateX(10px); opacity: 0; }
        18%, 100% { transform: translateX(0); opacity: 0; }
    }

    @keyframes swipe-icon-left {
        0%, 30% { transform: translateX(0); opacity: 0; }
        37% { transform: translateX(-8px); opacity: 1; }
        47% { transform: translateX(-10px); opacity: 0; }
        48%, 100% { transform: translateX(0); opacity: 0; }
    }
}

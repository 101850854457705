.layout--content {

    .section-left {
        &__inside {
            display: flex;
            align-items: baseline;
        }

        .social-element {
            margin: 0 0 2px 80px;
        }
    }

    @include screen(medium) {
        .section-left {
            text-align: right;

            &__inside {
                display: initial;
            }

            .social-element {
                margin: 80px 0 0;
            }
        }

        .section-main,
        .section-right {
            min-height: 400px;

            &__inside {
                border-top: 2px solid map-get($colors, text);
            }
        }
    }
}

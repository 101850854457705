
body {
    position: relative;
    background: map-get($colors, orange);

    @each $color, $contrast in $contrasts {
        &.layout--#{$color} {
            background: map-get($colors, $color);
        }
    }
}

.layout__columns,
.section-blog {
    position: relative;
    margin: 0 20px;

    @include screen(medium) {
        &--3cl,
        &--2cll {
            @include clear-after;

            > .section-left,
            > .section-main,
            > .section-right {
                float: left;
                width: 33.33333333%;
                min-height: 1px;
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        &--2cll {
            > .section-main {
                width: 66.66666666%;
            }
        }
    }

    @include screen(large) {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }
}

.section-blog {
    padding-top: 80px;
}

.mod_article,
.section-left,
.news-details {

    h1 {
        margin: -5px 0 0;
        font-size: 50px;
        line-height: 45px;
        text-transform: uppercase;

        @include screen(medium) {
            line-height: 1;
        }
    }

    h2 {
        margin: 4em 0 1.5em;
        font-size: 18px;
        line-height: 1.2222;
        font-weight: map-get($font-weight, bold);
        text-transform: uppercase;
    }

    h3 {
        margin: 3em 0 1.5em;
        font-size: 18px;
        line-height: 1.2222;
        font-weight: map-get($font-weight, bold);
    }
}

.section-footer {
    padding: 60px 20px 20px;
    font-weight: map-get($font-weight, regular);
    text-align: center;

    p {
        margin: 0;
    }

    &__slogan {
        margin: 0 0 25px !important;
        font-size: 30px;
        font-weight: map-get($font-weight, bold);
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.06em;
    }

    &__contact {
        max-width: 800px;
        margin: 0 auto;
    }

    &__social {
        margin-bottom: 25px;

        &-icon {
            width: 20px;
            height: 20px;
            margin: 0 10px;

            @include social-icons;
        }

        @include screen(medium) {
            margin-bottom: 0;
        }
    }

    &__copyright {
        margin-top: 60px;
        font-size: 11px;

        .mod_navigation, ul, li {
            display: inline;
        }

        li {
            &:before {
                content: "  |  ";
                text-decoration: none;
            }
        }

        a {
            position: relative;
            display: inline-block;
        }
    }
}

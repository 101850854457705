
.postit {
    overflow: hidden;
    margin: 20px 0;

    p {
        margin: 0;
    }

    &__number {
        font-size: 75px;
        font-weight: map-get($font-weight, black);
        line-height: 1;
    }

    &__title {
        margin: 40px 0 15px !important;
        font-size: 15px;
        font-weight: map-get($font-weight, black);
        text-transform: uppercase;
    }

    &__text {

    }

    &__link {
        @include link-button(166, 37);
        font-weight: map-get($font-weight, regular);
        margin-top: 2em;
    }

    &__inside {
        display: block;
        position: relative;
        padding: 25px 40px 25px 0;
        text-decoration: none;
        border-top: 2px solid $text-color;

        @include screen(medium, max-width, true) {
            padding-left: 20px;
            padding-right: 20px;
            border-top-color: transparent;
        }

        @each $color in (blue, yellow, green, pink) {
            &--#{$color} {
                @include postit($color);

                &:before {
                    top: -1.5px;
                }
            }
        }
    }
}

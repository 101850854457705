@mixin grid-row() {
    margin-left: -20px;
    margin-right: -20px;

    @include clear-after;
}

@mixin grid-col($width: 100%) {
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    width: $width;
}

@include screen(medium) {
    .row {
        @include grid-row;
    }

    .row > * {
        padding-left: 20px;
        padding-right: 20px;
    }

    .col, .col-3, .col-4, .col-6, .col-8 {
        @include grid-col;
    }

    .col-right {
        float: right;
    }

    .col-3 {
        width: 25%;
    }

    .col-4 {
        width: 33.33333333%;
    }

    .col-6 {
        width: 50%;
    }

    .col-8 {
        width: 66.66666667%;
    }

    .offset-3 {
        margin-left: 25%;
    }

    .offset-4 {
        margin-left: 33.33333333%;
    }

    .offset-8 {
        margin-left: 66.66666667%;
    }
}
